import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Layout } from "../components/organisms/Layout"
import Masthead from "../components/molecules/Masthead"
import SignUp from "../components/organisms/SignUp"

const AboutSection = styled.section`
  grid-column: 2/-2;
  margin: 0 0 4rem 0;
  p {
    margin-bottom: 1rem;
  }
  h2 {
    margin-bottom: 1rem;
    font-size: ${({ theme }) => theme.typography.h3};
  }
  h3 {
    font-size: ${({ theme }) => theme.typography.h4};
  }
  ul {
    margin-bottom: 1rem;
    list-style-position: inside;
  }
  ul li {
    line-height: 1.6;
  }
  @media (min-width: 992px) {
    grid-column: 4/-4;
  }
`

const SkillsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  list-style-type: none;
  margin-top: 1rem;
  padding-left: 0;
  li {
    &:not(:last-of-type) {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`

const SkillsTag = styled.span`
  background: ${({ theme }) => theme.colors.background};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 0.9rem;
  display: block;
`

const WhatToExpectPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="How I Work" />
      <Masthead style={{ marginBottom: "2rem" }}>
        <h1
          style={{
            textAlign: "center",
            marginBottom: "1rem",
            fontSize: "3rem",
          }}
        >
          My Philosophy on Work
        </h1>
        <p style={{ textAlign: "center", fontSize: "1.25rem" }}>
          I value my physical and mental wellbeing over everything else.
        </p>
      </Masthead>
      <AboutSection>
        <h2>What does that actually mean?</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero ab,
          facilis, suscipit velit similique molestiae quisquam animi harum sit
          necessitatibus quidem pariatur ipsum eaque sed impedit omnis,
          architecto non itaque. Laborum corrupti voluptate illo praesentium
          voluptas, sapiente obcaecati temporibus iste!
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur,
          ut:
        </p>
        <ul>
          <li>Responsive HTML/CSS</li>
          <li>Performant websites</li>
          <li>WordPress solutions</li>
          <li>Ongoing support and maintenance</li>
        </ul>
        <p>
          The work that I provide is of the highest quality, is fully
          responsive, and is tested on a wide range of devices.
        </p>
        <h2>Lorem Ipsum</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum
          consectetur nostrum laboriosam consequatur qui autem voluptatibus
          saepe itaque dignissimos omnis.
        </p>
        <h3>Programming Languages I Use:</h3>
        <SkillsList>
          <li>
            <SkillsTag>HTML</SkillsTag>
          </li>
          <li>
            <SkillsTag>CSS</SkillsTag>
          </li>

          <li>
            <SkillsTag>JavaScript</SkillsTag>
          </li>
          <li>
            <SkillsTag>PHP</SkillsTag>
          </li>
        </SkillsList>
        <h3>Tools I Use:</h3>
        <SkillsList>
          <li>
            <SkillsTag>git</SkillsTag>
          </li>
          <li>
            <SkillsTag>npm</SkillsTag>
          </li>
          <li>
            <SkillsTag>SASS</SkillsTag>
          </li>
          <li>
            <SkillsTag>vscode</SkillsTag>
          </li>
          <li>
            <SkillsTag>webpack</SkillsTag>
          </li>
          <li>
            <SkillsTag>woocommerce</SkillsTag>
          </li>
        </SkillsList>
        <h3>Web Frameworks I Like:</h3>
        <SkillsList>
          <li>
            <SkillsTag>React</SkillsTag>
          </li>
          <li>
            <SkillsTag>Gatsby</SkillsTag>
          </li>
        </SkillsList>
      </AboutSection>
      <SignUp />
    </Layout>
  )
}

export default WhatToExpectPage
